import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './RelativeAnimationStyles.scss'

class RelativeAnimation extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      numAnimations: props.animations.length,
      currentAnimStage: 0
    }
    this.timeouts = []
  }

  updateAnimations = () => {
    let i = this.state.currentAnimStage
    let runningDelay = 0
    while (i < this.props.animations.length - 1 && this.props.animations[i].triggerNext) {
      let triggerNextAnimationTimeout = (j, delay) => {
        let t = setTimeout(() => {
          this.setState({ currentAnimStage: j + 1 })
        }, delay)
        return t
      }
      runningDelay += this.props.animations[i].delay
      let timeout = triggerNextAnimationTimeout(i, runningDelay)
      this.timeouts.push(timeout)
      i += 1
    }
  }

  componentDidMount() {
    this.updateAnimations()
  }

  componentDidUpdate(prevProps) {
    if (this.state.currentAnimStage < this.props.animations.length - 1 &&
        !prevProps.animations[this.state.currentAnimStage].triggerNext &&
        this.props.animations[this.state.currentAnimStage].triggerNext) {
      this.updateAnimations()
    }
  }

  componentWillUnmount() {
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i])
    }
  }

  render() {
    const { animations, children } = this.props

    let indecesOfStylesToInclude = animations[this.state.currentAnimStage].includeStyles

    let styles = {}


    if (!indecesOfStylesToInclude || indecesOfStylesToInclude.length === 0) {
      for (let i = 0; i <= this.state.currentAnimStage; i++) {
        styles = {
          ...styles,
          ...animations[i].styles
        }
      }
    } else {
      for (let i = 0; i < indecesOfStylesToInclude.length; i++) {
        styles = {
          ...styles,
          ...animations[indecesOfStylesToInclude[i]].styles
        }
      }
    }

    const childrenWithStyles = React.Children.map(children, (child) => {
      return React.cloneElement(child, { animStyles : styles })
    })

    return (
      <React.Fragment>
       { childrenWithStyles }
      </React.Fragment>
    )
  }
}

RelativeAnimation.propTypes = {
  animations: PropTypes.arrayOf(
    PropTypes.shape({
      styles: PropTypes.shape({}),
      triggerNext: PropTypes.bool,
      delay: PropTypes.number.isRequired,
      includeStyles: PropTypes.arrayOf(PropTypes.number)
    }).isRequired
  ),
  children: PropTypes.element.isRequired
}


export default RelativeAnimation