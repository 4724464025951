import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { buildComponentsFromHtmlAst } from '../../utils/helpers'

import './ServiceStyles.scss'

import ConditionalWrap from '../conditionalWrap/ConditionalWrap'
import RiseUp from '../riseUp/RiseUp'
import RelativeAnimation from '../relativeAnimation/RelativeAnimation'

import { colorToStr, ColorType } from '../../utils/color'

const AnimatedImage = (props) => {
  const { animStyles, image } = props
  return (
    <div 
      className="image-wrapper" 
      style={{
        ...animStyles,
        backgroundImage: `url("${image.file.url}")`
      }}
    >
      <div className="bg-overlay" />
    </div>
  )
}

const Service = (props) => {
  const {
    name,
    description,
    image,
    price,
    priceDetails,
    associatedBackgroundColor,
    associatedTextColor,
    id,
    mini,
    index,
  } = props
  let wrapClasses = "service"
  let first = image ? (
    <ConditionalWrap
      condition={!mini}
      wrap={children => (
        <RelativeAnimation 
          key={index}
          animations={[
            {
              styles: {
                paddingTop: '0.5%',
                width: 0,
                transition: 'all 0.7s ease',
              },
              triggerNext: true,
              delay: 900
            },
            {
              styles: {
                width: '40%',
              },
              triggerNext: true,
              delay: 400,

            },
            {
              styles: {
                transition: 'all 0.7s ease',
              },
              includeStyles: [2],
              triggerNext: false,
              delay: 0

            }
          ]}
        >
          { children }
        </RelativeAnimation>
      )}
    >
      <AnimatedImage image={image} />
    </ConditionalWrap>
  ) : null
  let second = (
    <div 
      className="text-wrapper" 
      style={{ 
        backgroundColor: !mini ? `${colorToStr(associatedBackgroundColor.color[0])}` : "none",
        color: !mini ? `${colorToStr(associatedTextColor.color[0])}` : "none" 
      }}
    >
      <RiseUp delay={mini ? 300 : 1800}>
        <div className="header">
          <h3>{name}</h3>
          { priceDetails !== "dummy" ? 
            <span>{`$${price} ${priceDetails}`}</span>
            :
            <span>{`$${price} `}</span>
          }
        </div>
        <div className="contentful-md full-size-only">
          {description && buildComponentsFromHtmlAst(description.childMarkdownRemark.htmlAst.children) }
          { !mini &&
            <Link 
              to="contact"
              state={{
                serviceOfInterest: name,
              }}
            >
              Get Started!
            </Link>
          }
        </div>
      </RiseUp>
    </div>
  )
  if (index % 2 !== 0) {
    wrapClasses += " odd"
    let tmp = first
    first = second
    second = tmp
  }

  return (
    <ConditionalWrap
      condition={mini}
      wrap={children => (<Link id={id} className={wrapClasses} to={`services#${name.replace(/ /g, '-')}`}>{ children }</Link>)}
      elseWrap={children => (<div className={wrapClasses} style={props.animStyles}><div className="anchor" id={id}/>{ children }</div>)}
    >
      <div className="flush-box" style={{ backgroundColor: `${colorToStr(associatedBackgroundColor.color[0])}` }}/>
      { first }
      { second }
    
    </ConditionalWrap>
  )
}

Service.defaultProps = {
  mini: false,
  priceDetails: "dummy",
  index: 0,
  associatedBackgroundColor: null,
  associatedTextColor: null,
}

Service.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      htmlAst: PropTypes.shape({
        children: PropTypes.array
      })
    })
  }),
  image: PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    })
  }),
  price: PropTypes.number.isRequired,
  priceDetails: PropTypes.string,
  associatedBackgroundColor: ColorType,
  associatedTextColor: ColorType,
  mini: PropTypes.bool,
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
}

export default Service