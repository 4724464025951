import React from 'react'
import PropTypes from 'prop-types'

import './PageHeaderStyles.scss'

const PageHeader = ({
  title,
}) => {
  return (
    <div className="section page-header">
      <div className="content-wrapper">
        <h1>{ title }</h1>
      </div>
    </div>
  )
}

PageHeader.defaultProps = {
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageHeader
