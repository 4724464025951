import React from 'react'
import { graphql } from 'gatsby'

import { buildComponentsFromHtmlAst } from '../utils/helpers'

import Layout from '../components/layout/Layout'
import SEO from '../components/seo'

import PageHeader from '../components/pageHeader/PageHeader'
import Service from '../components/service/Service'
import RelativeAnimation from '../components/relativeAnimation/RelativeAnimation'

import './styles/ServicesPageStyles.scss'

const ServicesPage = ({ data }) => {
  const {
    title,
    navbar,
    pageIntroText,
    seo,
    services
  } = data.contentfulServicesPage
  return (
    <Layout navbarData={navbar}>
      <SEO { ...seo } />
      <PageHeader title={title} />
      <div className="section service-page">
        <div className="content-wrapper">
          <div className="section page-header-text">
            { pageIntroText && buildComponentsFromHtmlAst(pageIntroText.childMarkdownRemark.htmlAst.children) }
          </div>
          <div>
            {
              services.map((serviceData, index) => {
                return (
                  <RelativeAnimation 
                    key={index}
                    animations={[
                      {
                        styles: {
                          left: (index % 2 === 0) ? '100%' : '-100%',
                          opacity: 0,
                          transition: 'all 1s ease',
                        },
                        triggerNext: true,
                        delay: 300
                      },
                      {
                        styles: {
                          left: 0,
                          opacity: 1,
                        },
                        triggerNext: false,
                        delay: 0
                      }
                    ]}
                  >
                    <Service id={serviceData.name.replace(/ /g, '-')} index={index} {...serviceData} />
                  </RelativeAnimation>
                )
              })
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ServicesPageQuery {
    contentfulServicesPage {
      title
      navbar {
        ...NavbarFragment
      }
      pageIntroText {
        childMarkdownRemark {
          htmlAst
        }
      }
      seo {
        ...SeoFragment
      }
      services {
        name
        description {
          childMarkdownRemark {
            htmlAst
          }
        }
        price
        priceDetails
        image {
          description
          file {
            url
          }
        }
        associatedBackgroundColor {
          ...ColorFragment
        }
        associatedTextColor {
          ...ColorFragment
        }
      }
    }
  }
`

export default ServicesPage
